/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography } from "antd";
import eChart from "./configs/eChart";
import axios from "axios";
import url from "../../pages/host";
import { useEffect, useState } from "react";

function EChart() {
  const [loading,setloading]=useState(true)
  const { Title, Paragraph } = Typography;
var [chart,setChart]=useState(eChart)
  const items = [
    {
      Title: "3,6K",
      user: "Users",
    },
    {
      Title: "2m",
      user: "Clicks",
    },
    {
      Title: "$772",
      user: "Sales",
    },
    {
      Title: "82",
      user: "Items",
    },
  ];
useEffect(()=>{
  var diogramma=[]
  axios.get(`${url}/api/zakaz`).then(res=>{
    for (let j = 0; j < 12; j++) {
     diogramma.push(0)
      for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].time_create.slice(5,7)==`${j}`.padStart(2,"0")) {
        diogramma[j]=diogramma[j]+res.data[i].price
      }
      }
  }
  var a=eChart
  setloading(false)
  a.series[0].data=diogramma
  setChart(a) 
   console.log(a);
  })
},[])
  return (
    <>
  {loading?<div>Loading..</div>:<div>
  <div id="chart">
  <ReactApexChart
    className="bar-chart"
    options={chart.options}
    series={chart.series}
    type="bar"
    height={220}
  />
</div>
  </div>}
      {/*
      <div className="chart-vistior">
        <Title level={5}>Active Users</Title>
        <Paragraph className="lastweek">
          than last week <span className="bnb2">+30%</span>
        </Paragraph>
        <Paragraph className="lastweek">
          We have created multiple options for you to put together and customise
          into pixel perfect pages.
        </Paragraph>
        <Row gutter>
          {items.map((v, index) => (
            <Col xs={6} xl={6} sm={6} md={6} key={index}>
              <div className="chart-visitor-count">
                <Title level={4}>{v.Title}</Title>
                <span>{v.user}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div>*/}
    </>
  );
}

export default EChart;
