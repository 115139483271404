import { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  Button,
  List,
  Descriptions,
  Avatar,
  Radio,
  Switch,
  Upload,
  message,
  Select,
  Modal,
} from "antd";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";

import BgProfile from "../assets/images/bg-profile.jpg";
import profilavatar from "../assets/images/face-1.jpg";
import convesionImg from "../assets/images/face-3.jpg";
import convesionImg2 from "../assets/images/face-4.jpg";
import convesionImg3 from "../assets/images/face-5.jpeg";
import convesionImg4 from "../assets/images/face-6.jpeg";
import convesionImg5 from "../assets/images/face-2.jpg";
import project1 from "../assets/images/home-decor-1.jpeg";
import project2 from "../assets/images/home-decor-2.jpeg";
import project3 from "../assets/images/home-decor-3.jpeg";
import axios from "axios";
import url from "./host";

function Profile() {

  const [imageURL, setImageURL] = useState(false);
  const [isModalOpen,setIsModalOpen] = useState(false);
  const [User,setUser]=useState([])

  const [, setLoading] = useState(false);


  useEffect(()=>{
    axios.get(`${url}/auth/oneuser`,{headers:{Authorization:"Bearer "+sessionStorage.getItem("token")}}).then(res=>{
      res.data.map(item=>{
        setUser(item)
      })
    })
  },[])

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  
  function putModal(){
    setIsModalOpen(true)
    setTimeout(() => {
      document.querySelector("#patronymic").value=User.patronymic
      document.querySelector("#surname").value=User.surname
      document.querySelector("#username").value=User.username
      document.querySelector("#phone").value=User.phone
      document.querySelector("#email").value=User.email
      document.querySelector("#login").value=User.login
      document.querySelector("#password").value=User.password
    }, 100);
  }

  function putUsers(){
    var data=new FormData()
    data.append("patronymic",document.querySelector("#patronymic").value)
    data.append("surname",document.querySelector("#surname").value)
    data.append("username",document.querySelector("#username").value)
    data.append("phone",document.querySelector("#phone").value)
    data.append("email",document.querySelector("#email").value)
    data.append("login",document.querySelector("#login").value)
    data.append("position_id",User.position_id)
    data.append("password",document.querySelector("#password").value)
    axios.put(`${url}/auth/users/super/${User.id}`,data,{headers:{Authorization:"Bearer "+sessionStorage.getItem("token")}}).then(res=>{
    alert("Измененный")
    setIsModalOpen(false)
    axios.get(`${url}/auth/oneuser`,{headers:{Authorization:"Bearer "+sessionStorage.getItem("token")}}).then(res=>{
    res.data.map(item=>{
      setUser(item)
    })
    })
    })
    .catch(err=>{
    alert("Не изменилось")
    })
  }

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(false);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
        setImageURL(false);
      });
    }
  };

  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];

  const uploadButton = (
    <div className="ant-upload-text font-semibold text-dark">
      {<VerticalAlignTopOutlined style={{ width: 20, color: "#000" }} />}
      <div>Upload New Project</div>
    </div>
  );

  const data = [
    {
      title: "Sophie B.",
      avatar: convesionImg,
      description: "Hi! I need more information…",
    },
    {
      title: "Anne Marie",
      avatar: convesionImg2,
      description: "Awesome work, can you…",
    },
    {
      title: "Ivan",
      avatar: convesionImg3,
      description: "About files I can…",
    },
    {
      title: "Peterson",
      avatar: convesionImg4,
      description: "Have a great afternoon…",
    },
    {
      title: "Nick Daniel",
      avatar: convesionImg5,
      description: "Hi! I need more information…",
    },
  ];

  const project = [
    {
      img: project1,
      titlesub: "Project #1",
      title: "Modern",
      disciption:
        "As Uber works through a huge amount of internal management turmoil.",
    },
    {
      img: project2,
      titlesub: "Project #2",
      title: "Scandinavian",
      disciption:
        "Music is something that every person has his or her own specific opinion about.",
    },
    {
      img: project3,
      titlesub: "Project #3",
      title: "Minimalist",
      disciption:
        "Different people have different taste, and various types of music, Zimbali Resort",
    },
  ];

  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>

      <Card
        style={{padding:'10px'}}
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar size={74} shape="square" src="https://storage.kun.uz/source/3/ABLFUWSTx_SmVnLejVpTqCrNPvDfHD27.jpg" />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">{User.username}</h4>
                  <p>SuperAdmin</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
            </Col>
          </Row>
        }
      ></Card>
       
      
      <Row gutter={[24, 0]}>
       {/*
        <Col span={24} md={12} className="mb-24 ">
          <Card
            bordered={false}
            className="header-solid h-full"
            title={<h6 className="font-semibold m-0">Platform Settings</h6>}
          >
            <ul className="list settings-list">
              <li>
                <h6 className="list-header text-sm text-muted">ACCOUNT</h6>
              </li>
              <li>
                <Switch defaultChecked />

                <span>Email me when someone follows me</span>
              </li>
              <li>
                <Switch />
                <span>Email me when someone answers me</span>
              </li>
              <li>
                <Switch defaultChecked />
                <span>Email me when someone mentions me</span>
              </li>
              <li>
                <h6 className="list-header text-sm text-muted m-0">
                  APPLICATION
                </h6>
              </li>
              <li>
                <Switch defaultChecked />
                <span>New launches and projects</span>
              </li>
              <li>
                <Switch defaultChecked />
                <span>Monthly product updates</span>
              </li>
              <li>
                <Switch defaultChecked />
                <span>Subscribe to newsletter</span>
              </li>
            </ul>
          </Card>
        </Col>
*/}
        <Col style={{maxWidth: '100%',flex:'100%'}} span={24} md={12} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Profile Information</h6>}
            className="header-solid h-full card-profile-information"
            extra={<Button onClick={()=>{putModal()}} type="link">{pencil}</Button>}
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <p className="text-dark">
             {User.login}
            </p>
            <hr className="my-25" />
            <Descriptions title={User.username}>
              <Descriptions.Item label="Full Name" span={3}>
                {User.surname} {User.username}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile" span={3}>
               {User.phone}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
               {User.email}
              </Descriptions.Item>
              <Descriptions.Item label="Inn" span={3}>
                {User.inn}
              </Descriptions.Item>
              {/*<Descriptions.Item label="Social" span={3}>
                <a href="#pablo" className="mx-5 px-5">
                  {<TwitterOutlined />}
                </a>
                <a href="#pablo" className="mx-5 px-5">
                  {<FacebookOutlined style={{ color: "#344e86" }} />}
                </a>
                <a href="#pablo" className="mx-5 px-5">
                  {<InstagramOutlined style={{ color: "#e1306c" }} />}
                </a>
              </Descriptions.Item>*/}
            </Descriptions>
          </Card>
        </Col>
    
      </Row>

      <Modal title="Users" visible={isModalOpen} onOk={()=>putUsers()} onCancel={()=>setIsModalOpen(false)}>
      <div className="one">
      <br /><label htmlFor="patronymic">Patronymic</label><br />
      <input type="text" placeholder="patronymic" id="patronymic"/>
      <br /><label htmlFor="surname">Surname</label><br />
      <input type="text" placeholder="surname" id="surname"/>
      <br /><label htmlFor="username">Username</label><br />
      <input type="text" placeholder="username" id="username"/>
      <br /><label htmlFor="phone">Phone</label><br />
      <input type="text" placeholder="phone" id="phone"/>
     
      <br /><label htmlFor="email">Email</label><br />
      <input type="text" placeholder="email" id="email"/>
      <br /><label htmlFor="login">Login</label><br />
      <input type="text" placeholder="login" id="login"/>
      <br /><label htmlFor="password">Password</label><br />
      <input type="text" placeholder="password" id="password"/>
      </div>
      </Modal>
      {/*
      <Card
        bordered={false}
        className="header-solid mb-24"
        title={
          <>
            <h6 className="font-semibold">Projects</h6>
            <p>Architects design houses</p>
          </>
        }
      >
        <Row gutter={[24, 24]}>
          {project.map((p, index) => (
            <Col span={24} md={12} xl={6} key={index}>
              <Card
                bordered={false}
                className="card-project"
                cover={<img alt="example" src={p.img} />}
              >
                <div className="card-tag">{p.titlesub}</div>
                <h5>{p.title}</h5>
                <p>{p.disciption}</p>
                <Row gutter={[6, 0]} className="card-footer">
                  <Col span={12}>
                    <Button type="button">VIEW PROJECT</Button>
                  </Col>
                  <Col span={12} className="text-right">
                    <Avatar.Group className="avatar-chips">
                      <Avatar size="small" src={profilavatar} />
                      <Avatar size="small" src={convesionImg} />
                      <Avatar size="small" src={convesionImg2} />
                      <Avatar size="small" src={convesionImg3} />
                    </Avatar.Group>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
          <Col span={24} md={12} xl={6}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader projects-uploader"
              showUploadList={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageURL ? (
                <img src={imageURL} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Col>
        </Row>
      </Card>
      */}
    </>
  );
}

export default Profile;
